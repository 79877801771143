export default [
    {
        label: '등급',
        field: 'level',
        sort: true
    },
    {
        label: '이메일',
        field: 'email',
        sort: true
    },
    {
        label: '가입일',
        field: 'reg_date',
        sort: true
    },
];