<template>
  <mdb-container>
    <mdb-col>
      <div class="mt-5 sub_content">
        <h3>멤버 소개</h3>
      </div>
      <div class="my-3 card">
        <mdb-row>
          <mdb-col></mdb-col>
          <mdb-col>
            <mdb-select v-model="basicOptions" placeholder="-검색선택-" @getValue="getSelectValue"/>
          </mdb-col>
          <mdb-col>
            <mdb-input v-model="search" placeholder="검색어를 입력하세요."/>
          </mdb-col>
          <mdb-col></mdb-col>
        </mdb-row>
      </div>
      <p class="mt-4 mb-1">총 게시물 <strong style="color: red">{{ totCount }}</strong> 건</p>
      <mdb-datatable-2
          class="mb-3 card"
          v-model="data"
          hover
          :searching="{value: search, field: fieldName} "
          @selected="onSelected($event)"
          noFoundMessage="데이터가 없습니다."/>
    </mdb-col>
    <mdb-col class="mb-5 text-right" style="text-align: center"></mdb-col>
  </mdb-container>
</template>

<script>
import {mdbCol, mdbRow, mdbSelect, mdbContainer, mdbDatatable2, mdbInput} from 'mdbvue';
import firebase from "firebase/app/";
import "firebase/firestore";
import user_columns from "../../../../assets/user_columns";

export default {
  name: 'MemberManager',
  components: {
    mdbContainer,
    mdbSelect,
    mdbRow,
    mdbCol,
    mdbDatatable2,
    mdbInput
  },
  data() {
    return {
      basicOptions: [
        {text: "이메일", value: "email"},
        {text: "등급", value: "level"},
      ],
      selected: null,
      search: '',
      data: {
        columns: user_columns,
        rows: []
      },
      fieldName: 'email',
      totCount: 0,
      level: 'USER',
    }
  },
  mounted() {
    this.onFirebaseGetAllArrayData();
  },
  methods: {
    onSelected(value) {
      this.selected = value;
    },
    getSelectValue(value) {
      this.fieldName = value
    },
    onFirebaseGetAllArrayData() {
      const self = this;
      const db = firebase.firestore();
      // let counter = 0;
      db.collection("user").get()
          .then((querySnapshot) => {
            self.totCount = querySnapshot.size;
            // counter = self.totCount;
            querySnapshot.forEach((doc) => {
              const _data = doc.data();
              console.log(_data);
              // _data.order_number = counter;
              // counter--;
              _data["id"] = doc.id;
              const date = new Date(_data.reg_date.seconds * 1000);
              _data.reg_date = self.getParseDate(date);
              self.data.rows.push(_data);
              if (_data.admin) {
                self.level = '관리자'
              } else {
                self.level = '유저'
              }
              _data["level"] = self.level;
              console.log(self.data.rows);
            });
          })
          .catch(function (e) {
            console.log(e);
          });
    },
    getParseDate(date) {
      return date.getFullYear() +
          "-" + (date.getMonth() + 1) +
          "-" + date.getDate() +
          " " + date.getHours() +
          ":" + date.getMinutes() +
          ":" + date.getSeconds()
    }
  }
}
</script>

<style scoped>

.sub_content {
  text-align: center;
}

.sub_content h3 {
  font-weight: bold;
}

</style>